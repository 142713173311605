<template>
  <div>
    <Alert v-show="conflictEntity.orderQauntity !== 0" type="error" show-icon>
      订单冲突提醒
      <template slot="desc">
        当前系统包含 <span class="text-red text-20">{{conflictEntity.orderQauntity}}</span> 个订单冲突 , 请及时调整点位以免导致上下刊运维异常。
        <Icon type="md-arrow-forward" class="m-l-10" /><a @click="SearchOrders(true)">点击显示全部冲突订单</a>
        <Icon type="md-arrow-back" />
      </template>
    </Alert>

    <Row :gutter="8" class="m-b-5">
      <i-col span="4">
        <Select size="small" v-model="searchStatus" placeholder="订单状态" clearable>
          <Option v-show="allowStatus.indexOf(status.value)>-1" v-for="(status,index) in statuslist" :key="index"
            :value="status.value">{{status.name}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select size="small" v-model="query.day" placeholder="创建时间" clearable>
          <Option value="14">14天内创建</Option>
          <Option value="7">7天内创建</Option>
          <Option value="3">3天内创建</Option>
          <Option value="0">当天创建</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <DatePicker size="small" :show-week-numbers="true" type="daterange" v-model="orderSchdule"
          @on-change="scheduleChange" style="width: 100%" placement="top-start" placeholder="订单档期"></DatePicker>
      </i-col>
      <i-col span="4">
        <Select size="small" v-model="query.type" placeholder="发布类型" clearable>
          <Option v-for="(orderType,index) in orderTypeList" :key="index" :value="orderType.type">{{orderType.name}}
          </Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select size="small" v-model="query.companyId" placeholder="所属公司" clearable @on-change="initDepartmentList">
          <Option v-for="(channel,index) in channellist" :key="index" :value="channel.id">{{channel.name}}</Option>
        </Select>
      </i-col>
      <i-col span="4">
        <Select size="small" v-model="query.departmentId" placeholder="所属部门" clearable @on-change="getChannelUserList">
          <Option v-for="(department,index) in departmentList" :key="index" :value="department.id">{{department.name}}
          </Option>
        </Select>
      </i-col>
      <i-col span="4" class="m-t-5">
        <Select size="small" v-model="query.userId" placeholder="归属销售" clearable>
          <Option v-for="user in channelUsers" :key="user.id" :value="user.id">{{ user.name}}</Option>
        </Select>
      </i-col>
      <i-col span="8" class="m-t-5">
        <i-input size="small" v-model='query.keyword' placeholder="客户名称/投放品牌/编号/备注"></i-input>
      </i-col>
      <i-col span="6" class="m-t-5">
        <Button size="small" type="primary" icon="ios-search" @click="SearchOrders(false)">搜索</Button>
        <Button size="small" type="success" class="m-l-5" v-if="isAuth('order_index_create')"
          @click="handleCreateOrder">订单录入</Button>
      </i-col>
    </Row>

    <Table size="small" stripe :columns="columndata" :data="tableData" :row-class-name="rowClassName"
      @on-sort-change="handleTableSort"></Table>
    <div class="paging_style">
      <Page size="small" :total="total" :page-size="query.pageSize" :current="query.pageNumber" show-total show-elevator
        @on-change="changePage"></Page>
    </div>

    <Modal v-model="delayModal" title="订单锁位时间延期设置" @on-ok="handleDelay" @on-cancel="delayModal=false">
      <div>
        <Label>延期时长：</Label>
        <InputNumber :min="1" v-model="delayDay" style="width:200px;"></InputNumber> <span>天</span>
      </div>
    </Modal>

    <Modal v-model="updateRemarkModal" width="400" footer-hide>
      <p slot="header" style="color:#f60;text-align:center">
          <Icon type="ios-information-circle"></Icon>
          <span>编辑订单备注</span>
      </p>

      <Row class="m-b-10">
        <i-col span="4" class="title">订单编号</i-col>
        <i-col span="20">{{ updateModel.code }}</i-col>
      </Row>
      <Row class="m-b-10">
        <i-col span="4" class="title">备注</i-col>
        <i-col span="20">
          <Input v-model="updateModel.remark" type="textarea" maxlength="100" show-word-limit :rows="4" placeholder="请输入订单描述备注信息..." />
        </i-col>
      </Row>
      <Row class="m-t-10">
        <i-col span="20" offset="4">
          <Button type="primary" @click="handleUpdateOrderRemark">保存并提交</Button>
        </i-col>
      </Row>
    </Modal>

    <purchase-resource-list ref="purchaseResourceList"/>
  </div>
</template>

<script>
import purchaseResourceList from '@/components/order/common/PurchaseResourceList'

import { formatOrderStatus, formatOrderDifference } from '@/utils/tagStatus'
import { GetCurrentSchedule, GetDateStr, getTimeDifference } from '@/utils/dateFormat'

import { getCompanyChild } from '@/api/os/company'
import { getSellerPage } from '@/api/os/companyuser'
import { getOrderTypeList, getOrderStatus, revoke, extendTime, getOrderPage, getConflictOrderQuantity, updateOrderRemark } from '@/api/order/order'

export default {
  components: { purchaseResourceList },
  data () {
    return {
      conflictEntity: {
        orderQauntity: 0,
        resourceQauntity: 0
      },
      delayModal: false, // 锁位延期Modal
      delayDay: 1, // 延期天数
      delayOrderId: null,
      channellist: [],
      departmentList: [],
      channelUsers: [],
      orderSchdule: [],
      total: 0,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      query: {
        pageNumber: 1,
        pageSize: 15,
        userId: '',
        companyId: null,
        status: '',
        day: '',
        startDate: '',
        endDate: '',
        keyword: '',
        showProductQuantity: true,
        showconflict: false,
        sort: ''
      },
      statuslist: [],
      orderTypeList: [], // 订单发布类型
      searchStatus: '',
      allowStatus: [1, 2, 3, 4, 6, 7, 8, 9, 10, 11],
      tableData: [],
      columndata: [
        { title: '编号', key: 'code', width: 145 },
        { title: '客户名称', key: 'advertiserName' },
        { title: '投放品牌', key: 'brandName', width: 120 },
        { title: '发布类型', key: 'typeName', width: 80 },
        // {
        //   title: '投放品牌',
        //   render: (h, params) => {
        //     if (params.row.type === 2) {
        //       return h('strong', { attrs: { style: 'color:green;' } }, params.row.typeName)
        //     } else if (params.row.type === 3) {
        //       return h('strong', { attrs: { style: 'color:#19be6b;' } }, params.row.typeName)
        //     } else {
        //       return h('span', params.row.brandName)
        //     }
        //   }
        // },
        {
          title: '备注',
          render: (h, data) => {
            const remarkContent = [h('span', data.row.remark)]
            // 广告商城的订单，需要显示有赞编号
            if (data.row.type === 12) {
              if (data.row.remark.length) {
                remarkContent.push(h('span', '，'))
              }
              remarkContent.push(h('span', '有赞订单编号：' + (data.row.yzOrderId ? data.row.yzOrderId : '-')))
            }

            // 订单除已完成状态外， 都可以编辑备注
            if (data.row.status !== 4) {
              remarkContent.push(h('a', {
                style: {
                  marginLeft: '5px'
                },
                on: {
                  click: () => {
                    this.updateModel.orderId = data.row.id
                    this.updateModel.code = data.row.code
                    this.updateModel.remark = data.row.remark
                    this.updateRemarkModal = true
                  }
                }
              }, [h('Icon', { props: { type: 'md-create', size: 18 } })]
              ))
            }

            return h('div', remarkContent)
          }
        },
        // { title: '档期天数', key: 'tradePublishday', width: 90, align: 'center' },
        {
          title: '所属代理商/销售',
          width: 150,
          render: (h, params) => {
            return h('span', params.row.companyName + ' / ' + params.row.userName)
          }
        },
        // {
        //   title: '投放档期',
        //   key: 'startDate',
        //   sortable: 'custom',
        //   render: (h, params) => {
        //     return h('span', GetCurrentSchedule(params.row.startDate, params.row.endDate))
        //   }
        // },
        {
          title: '投放档期',
          render: (h, data) => {
            const spans = []
            data.row.purchasedScheduleList.forEach(item => {
              spans.push(h('span', GetCurrentSchedule(item.startDate, item.endDate)))
              spans.push(h('br'))
            })
            return h('div', spans)
          }
        },
        {
          title: '采购资源统计',
          width: 120,
          render: (h, params) => {
            const spans = []
            params.row.orderProductQuantityBeanList.forEach(resource => {
              spans.push(h(
                'span',
                resource.productName
              ))
              spans.push(h(
                'span',
                {
                  attrs: {
                    class: 'text-orange text-12'
                  }
                },
                resource.quantity
              ))
              spans.push(h(
                'span',
                resource.unitName
              ))
              spans.push(h('br'))
            })
            return h('div', {
              attrs: {
                class: 'text-href'
              },
              on: {
                click: () => {
                  this.$refs.purchaseResourceList.showProductSkuList(params.row.id)
                }
              }
            }, spans)
          }
        },
        {
          title: '状态',
          align: 'center',
          width: 100,
          render: (h, params) => {
            return formatOrderStatus(h, params.row.status, params.row.statusName)
          }
        },
        {
          title: '保护倒计时',
          align: 'center',
          width: 110,
          render: (h, params) => {
            if (!params.row.fixed) {
              return h('span', {
                attrs: {
                  style: 'color:#19be6b;font-size:14px'
                }
              }, '不确定方案')
            } else if ([1, 2, 6, 7].includes(params.row.status)) {
              return formatOrderDifference(h, params.row.status, getTimeDifference(Date.now(), params.row.expireTime))
            } else {
              return h('span', {
                attrs: {
                  style: 'font-size:14px'
                }
              }, '-')
            }
          }
        },
        { title: '最后更新时间', key: 'updateTime' },
        {
          title: '操作',
          align: 'center',
          render: (h, params) => {
            return h('div', [
              this.isAuth('order_index_change')
                ? h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.handleEdit(params.row.id)
                    }
                  }
                }, '变更') : '',
              h('a', {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleShowDetail(params.row.id)
                  }
                }
              }, '详情'),
              this.isAuth('order_manage_delay_cancel') && (params.row.status === 1 || params.row.status === 6) // 一类商业为预定中、 二类商业为待付款
                ? h('a', {
                  style: { marginRight: '5px' },
                  on: {
                    click: () => {
                      this.delayDay = 1
                      this.delayModal = true
                      this.delayOrderId = params.row.id
                    }
                  }
                }, '延期') : '',
              this.isAuth('order_manage_delay_cancel') && (params.row.status === 1) // 代理商端预定中是不能修改的，这里新增撤销预定
                ? h('a', {
                  style: {
                    marginRight: '5px',
                    color: '#ef4f4f'
                  },
                  on: {
                    click: () => {
                      this.handleRevokeOrder(params)
                    }
                  }
                }, '撤销') : ''
            ])
          }
        }
      ],

      updateRemarkModal: false,
      updateModel: {
        orderId: null,
        code: null,
        remark: null
      }
    }
  },
  created () {
    getOrderStatus().then(res => {
      this.statuslist = res
    })

    getOrderTypeList().then(res => {
      this.orderTypeList = res
    })
    this.initChannelList()
    this.initOrderList()
    this.initConflictOrderList()
  },
  methods: {

    initChannelList () {
      getCompanyChild({ companyId: this.$store.getters.token.userInfo.companyId, type: this.$store.getters.token.userInfo.publisherId === this.$store.getters.token.userInfo.companyId ? 1 : 2, childType: 2, companyType: 1 }).then(res => {
        let array = [{
          id: res.companyId, name: res.companyName
        }]
        if (res.children.length) {
          array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
        }
        this.channellist = array
        if (!this.query.companyId) {
          if (array.length > 1) {
            this.query.companyId = null
          } else {
            this.query.companyId = this.companyId
          }
        }

        this.initDepartmentList()
      })
    },
    initDepartmentList () {
      if (this.query.companyId > 0) {
        getCompanyChild({ companyId: this.query.companyId, type: 2, childType: 2, companyType: 2 }).then(res => {
          let array = [{
            id: res.companyId, name: res.companyName
          }]
          if (res.children.length) {
            array = array.concat(this.formatCompanyArray(res.children).filter(x => x.id !== res.companyId))
          }
          this.departmentList = array
          // this.query.departmentId = null
          // if (res.length > 1) {
          //   this.query.departmentId = null
          // } else {
          //   this.query.departmentId = this.companyId
          // }
          this.getChannelUserList()
        })
      } else {
        this.query.departmentId = null
        this.departmentList = null
        this.getChannelUserList()
      }
    },
    // 重组公司列表结构
    formatCompanyArray (companyTree) {
      let resultArray = []
      companyTree.forEach(item => {
        resultArray.push({ id: item.companyId, name: item.companyName })
        if (item.children.length) {
          resultArray = resultArray.concat(this.formatCompanyArray(item.children))
        }
      })
      return resultArray
    },
    rowClassName (row, index) {
      return row.conflict ? 'table-conflict-row' : ''
    },
    initConflictOrderList () {
      const query = { status: JSON.stringify(this.allowStatus) }

      getConflictOrderQuantity(query).then(res => {
        this.conflictEntity = res
      })
    },
    initOrderList () {
      const state = []
      this.query.status = ''
      if (this.searchStatus === undefined || this.searchStatus === '') {
        this.query.status = JSON.stringify(this.allowStatus)
      } else {
        state.push(this.searchStatus)
        this.query.status = JSON.stringify(state)
      }
      getOrderPage(this.query).then(response => {
        this.total = response.totalRow
        this.tableData = response.list
        this.query.pageNumber = response.pageNumber
      })
    },
    scheduleChange () {
      const shcedule = this.orderSchdule
      if (shcedule[0] === '' || shcedule[1] === '') {
        this.query.startDate = ''
        this.query.endDate = ''
      } else {
        this.query.startDate = GetDateStr(shcedule[0], 1)
        this.query.endDate = GetDateStr(shcedule[1], 1)
      }
    },
    SearchOrders (showconflict) {
      this.query.showconflict = showconflict
      this.query.pageNumber = 1
      this.initOrderList()
    },
    changePage (currentPage) {
      this.query.pageNumber = currentPage
      this.initOrderList()
    },
    getChannelUserList () {
      if (this.query.companyId) {
        const data = {
          companyId: this.query.departmentId || this.query.companyId,
          pageNumber: 1,
          pageSize: 1000
        }
        getSellerPage(data).then(response => {
          this.channelUsers = response.list
        })
      } else {
        this.channelUsers = []
        this.query.userId = ''
      }
    },
    handleShowDetail (orderId) {
      this.$store.commit('set_order_ID', orderId)
      this.$store.commit('set_order_pageType', 'detail')
      // 设置传递参数
      const query = { orderId: orderId }
      this.$store.dispatch('getOrderBaseInfo', query)
    },
    handleEdit (orderId) {
      this.$store.commit('set_order_ID', orderId)
      this.$store.commit('set_order_pageType', 'edit')
      // 设置传递参数
      const query = { orderId: orderId }
      this.$store.dispatch('getOrderBaseInfo', query)
    },
    handleRevokeOrder (para) {
      this.$Modal.confirm({
        title: '确定要撤销',
        content: '<p>撤销订单后订单状态将变为已过期，资源全部释放,此操作不可逆转，确定要继续？</p>',
        loading: true,
        onOk: () => {
          const data = {
            orderId: para.row.id
          }
          revoke(data).then(res => {
            if (res.errcode === 0) {
              this.$Notice.success({ desc: '撤销操作成功' })
              this.initOrderList()
            }
          })
          this.$Modal.remove()
        },
        onCancel: () => {
        }
      })
    },
    handleDelay () {
      if (!this.delayDay) {
        this.$Notice.error({ desc: '请正确输入延期天数' })
        return false
      }
      this.$Modal.confirm({
        title: '确认对该订单进行锁位时间延期操作？',
        loading: true,
        onOk: () => {
          const postData = {
            orderId: this.delayOrderId,
            day: this.delayDay
          }
          extendTime(postData).then(res => {
            if (res && res.errcode === 0) {
              this.$Notice.success({ desc: '延期操作成功' })
              this.initOrderList()
            } else {
              this.$Notice.error({ title: '延期操作失败', desc: res.errmsg })
            }
          })
          this.$Modal.remove()
        }
      })
    },
    handleCreateOrder () {
      this.$store.commit('set_order_pageType', 'create')
    },
    handleTableSort (params) {
      this.query.pageNumber = 1
      if (params.order === 'normal') { // 取消排序
        this.query.sort = ''
      } else {
        this.query.sort = JSON.stringify([
          {
            direction: params.order,
            property: params.key
          }
        ])
      }
      this.initOrderList()
    },
    handleUpdateOrderRemark () {
      updateOrderRemark(this.updateModel).then(res => {
        if (res.errcode === 0) {
          this.$Notice.success({ desc: '订单备注编辑成功！' })
          this.initOrderList()
          this.updateRemarkModal = false
        }
      })
    }
  }
}
</script>
